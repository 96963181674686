import axios from "axios";

let LocalURL = process.env.REACT_APP_LOCAL_URL;
let ProdAWSURL = process.env.REACT_APP_AWS_URL;
let ProdVercelURL = process.env.REACT_APP_VERCEL_URL;

const API = axios.create({
  baseURL: `${process.env.REACT_APP_AWS_URL}/api/v2`,
  withCredentials: true,
});

export default API;
